.wrap {
    position: relative;
}
.container {
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 12px;
    padding: 12px !important;
    padding-right: 28px !important;
    color: #fff !important;
    max-height: 40vh;
    min-height: 82px;
    overflow: auto;
}

.container .container_editor_area {
    tab-size: 4ch;
    margin: 1.67em 0;
}

.container textarea:focus {
    border: none;
    outline: none !important;
}

.container .btn_variable {
    position: absolute;
    right: 12px;
    top: calc(50% - 12px)
}

.select_item {
    line-height: 1.5rem;
    padding: 8px 12px;
    font-size: 14px;
}

.select_item:hover {
    background-color: #f1f3f5;
}
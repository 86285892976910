.node_normal {
  background: var(--primary);
  color: var(--color-text);
  padding: 10px;
  min-width: 120px;
  box-shadow: var(--box-shadow);
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.react-flow__node.selected .node_normal {
  background: #043F8C;
}
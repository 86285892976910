.node-loop {
    background: var(--primary);
    padding: 10px;
    color: var(--color-text);
    box-shadow: var(--box-shadow);
    width: 70px;
    height: 70px;
    transform: rotate(45deg);
    box-sizing: unset;
}

.node-loop.active {
    background: #162D32;
    padding: 10px;
    color: var(--color-text);
    box-shadow: var(--box-shadow);
    width: 70px;
    height: 70px;
    transform: rotate(45deg);
    box-sizing: unset;
}
.node-loop.active .node-icon{
    background-color: #001F24;
}
.react-flow__node.selected .node-loop {
    background: #043F8C;
}

.node-loop-inside {
    transform: rotate(-45deg);
    position: absolute;
    top: 30px;
    right: 0px;
}
.node-loop-inside .action-node {
    position: absolute;
    right: 0;
    left: -8px;
    top: -75px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.node-normal-body-loop {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    min-width: 80px;
}

.react-flow__node.selected .node-loop .node-icon {
    background: #043F8C;
}
.node-normal {
    background: var(--primary);
    padding: 10px;
    border-radius: 5px;
    color: var(--color-text);
    min-width: 120px;
    box-shadow: var(--box-shadow);
}

.node-normal.active {
    background: #162D32;
    padding: 10px;
    border-radius: 5px;
    color: var(--color-text);
    min-width: 120px;
    box-shadow: var(--box-shadow);
}

.node-normal.active .node-icon{
    background-color: #001F24;
}

.node-normal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.action-node{
    position: absolute;
    right: 0;
    left: 0;
    top: -25px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.action-node .left-node{
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.react-flow__node.selected .node-normal { 
    background: #043F8C;
}
.react-flow__node.selected .node-normal .node-icon {
    background: #043F8C;
}
.node-comment {
    background: var(--comment);
    padding: 10px;
    border-radius: 5px;
    color: var(--color-text);
    min-width: 120px;
    max-width: 220px;
    box-shadow: var(--box-shadow);
}

.node-comment.active {
    background: #162D32;
    padding: 10px;
    border-radius: 5px;
    color: var(--color-text);
    min-width: 120px;
    max-width: 220px;
    box-shadow: var(--box-shadow);
}

.node-comment.active .node-icon{
    background-color: #001F24;
}
.text-wb {
    word-break: break-word;
}
.ms-0 {
    margin-left: 0px;
}

.node-comment .remove-node {
    position: absolute;
    right: 0;
    left: 0;
    top: -25px;
    margin-left: auto;
    margin-right: auto;
}
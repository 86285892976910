.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .25rem;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
}

.active {
  color: #228be6 !important;
  position: relative;
}

.line {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #228be6;
  height: 4px;
  width: 0;
  border-radius: 15px;
  transition: all 0.2s ease;
}

.title {
  padding-left: .25rem;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

.desc {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}